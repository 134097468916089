export default function useProductThumbnail(product: any, index: number = 0) {
  const hasPhotos = product?.photos?.length;

  if (!hasPhotos) return {};
  return product.photos[index];
}

export {
  useProductThumbnail
}
